import React from "react";
import { PageProps } from "gatsby";
import { styled, useTheme } from "@mui/material/styles";
import { MusicNoteSharp, AlbumSharp } from "@mui/icons-material";
import { groupBy } from "lodash";
import { Suspense, lazy } from "@uploadcare/client-suspense";
import Loader from "@components/loader";

import Layout from "@components/layout";
import VStack from "@components/vstack";
import DetailHeader from "@components/detail-header";
import SongDetailBlock from "@components/song-detail-block";
import { useSearchSongDetail } from "@lib/services/search";
import { SongOwner } from "@lib/interfaces";
import OwnerItem from "@src/components/owner-item";
import AlbumItem from "@components/album-item";
import useIsPhone from "@src/hook/useIsPhone";
import { Owner } from "@src/enum/ownerType";


const DetailContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  margin: theme.spacing(1, 0),
}));

function DetailPage(props: PageProps) {
  const theme = useTheme();
  const isPhone = useIsPhone();
  const params = new URLSearchParams(props.location.search);
  const songGuid = params.get("q");
  const { data, isLoading } = useSearchSongDetail(songGuid);
  const songOwners = groupBy(
    data?.songOwners,
    (b) => (b as SongOwner).type
  ); 
  const songOwnerOrder = Object.keys(songOwners);
  const ownerToBeSorted = [
    Owner.Lyric.toString(),
    Owner.Compose.toString(),
    Owner.Record.toString(),
  ];
  const sortedOwner = songOwnerOrder.sort(
    (a, b) => ownerToBeSorted.indexOf(a.toLocaleLowerCase()) - ownerToBeSorted.indexOf(b.toLocaleLowerCase())
  );


  const contentStyleObj = {
    border: "1px solid",
    borderRadius: "10px",
    padding: theme.spacing(2, 2),
    margin: theme.spacing(1, 8),
    background: "#FFF",
  };
  return (
    <Suspense fallback={<Loader />}>
    <Layout contentStyle={isPhone ? {} : contentStyleObj}>
      {!isLoading && <DetailHeader songName={data?.songDetail.songName} />}
      <DetailContainer>
        {isLoading && <p>Loading...</p>}
        {!isLoading && (
          <VStack spacing={1} sx={{ paddingBottom: theme.spacing(2) }}>
            <SongDetailBlock
              songDetail={data!.songDetail}
              showDuration={true}
            />
            {data?.songOwners &&
              sortedOwner.map((e) => (
                <OwnerItem
                  key={e}
                  item={songOwners[e]}
                  type={e.toLocaleLowerCase()}
                >
                  {((e.toLocaleLowerCase() === Owner.Compose &&
                    !songOwners.hasOwnProperty(
                      Owner.Lyric[0].toUpperCase() + Owner.Lyric.slice(1)
                    )) ||
                    e.toLocaleLowerCase() === Owner.Lyric) && (
                    <>
                      <MusicNoteSharp />
                      <span>音樂著作</span>
                    </>
                  )}
                  {e.toLocaleLowerCase() === Owner.Record && (
                    <>
                      <AlbumSharp />
                      <span>錄音著作</span>
                    </>
                  )}
                </OwnerItem>
              ))}
            {data?.songAlbums && <AlbumItem item={data.songAlbums} />}
          </VStack>
        )}
      </DetailContainer>
    </Layout>
    </Suspense>
  );
}

export default DetailPage;
