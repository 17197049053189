import React from "react";
import { styled } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import { navigate } from "gatsby";

interface DetailHeaderProp {
    songName?: string
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px",
  margin: "0px 12px",
  borderBottom: "2px solid #eee",
  [`.MuiSvgIcon-root`]: {
    position: "absolute",
    left: "8px",
  },
  [theme.breakpoints.up("sm")]: {
    justifyContent: "start",
    borderBottom: "1px solid #000",
  },
}));

const SongName = styled('span')(({ theme }) =>({
    fontSize: "18px",
    color: "444444",
    fontWeight: "700",
    maxWidth: "280px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      overflow: "inherit"
    },
}));

const BackArrow = styled(ArrowBack)(({ theme }) => ({
  cursor: "pointer",
  display: "block",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

function DetailHeader(props: DetailHeaderProp) {
  return (
    <Container>
      <BackArrow onClick={() => navigate(-1)} />
      <SongName>{props.songName}</SongName>
    </Container>
  );
}

export default DetailHeader;
