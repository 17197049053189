import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import HStack from "@components/hstack";
import { SongAlbum } from "@lib/interfaces";
import useIsPhone from "@src/hook/useIsPhone";


const TitleContain = styled("div")({
  display: "flex",
  alignItems: "center",
  [`span`]: {
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: "8px",
  },
});

const Container = styled(Paper)(({ theme }) => ({
  border: `solid ${theme.palette.primary.main} 1px`,
  borderRadius: "10px",
  overflow: "hidden",
  ["div.item"]: {
    position: "relative",
  },
  ["div.item:not(:last-child):after"]: {
    content: '""',
    position: "absolute",
    width: "calc(100% - 16px)",
    left: "8px",
    height: "2px",
    background: theme.palette.primary.main,
    bottom: "2px",
  },
}));

const ItemHeader = styled(HStack)(({ theme }) => ({
  padding: theme.spacing(1),
  color: "#5393FF",
  fontWeight: "bold",
  fontSize: "20px",
}));

const ItemContent = styled("div")(({ theme }) => ({
  paddingBottom: "8px",
  [".recordTitle "]: {
    color: "#5393FF",
    fontWeight: "700",
    fontSize: "18px",
    marginBottom: "12px",
  },
}));

const DataLabel = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  color: theme.palette.primary.main,
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface AlbumItemProps {
  item: SongAlbum[];
}

function AlbumItem(props: AlbumItemProps) {
  const isPhone = useIsPhone();
  return (
    <>
      <TitleContain>
        <span>含有此歌曲專輯</span>
      </TitleContain>

      {isPhone &&
        props.item.map((e, i) => (
          <Container variant="outlined" key={`Album_${i}`}>
            <ItemHeader>
              <Typography variant="subtitle1">{e.albumName}</Typography>
            </ItemHeader>
            <ItemContent>
              <DataLabel variant="subtitle2">發行公司．{e.issuer}</DataLabel>
              <DataLabel variant="subtitle2">
                製作公司．{e.makerCompany}
              </DataLabel>
              <DataLabel variant="subtitle2">出版日期．{e.pubDate}</DataLabel>
            </ItemContent>
          </Container>
        ))}

      {!isPhone && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>專輯名稱</StyledTableCell>
                <StyledTableCell>EAN/UPC</StyledTableCell>
                <StyledTableCell>出版形式</StyledTableCell>
                <StyledTableCell>出版日期</StyledTableCell>
                <StyledTableCell>發行公司</StyledTableCell>
                <StyledTableCell>製作公司</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.item.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.albumName}</StyledTableCell>
                  <StyledTableCell>{row.eanUpcs}</StyledTableCell>
                  <StyledTableCell>{row.mediaType}</StyledTableCell>
                  <StyledTableCell>{row.pubDate}</StyledTableCell>
                  <StyledTableCell>{row.issuer}</StyledTableCell>
                  <StyledTableCell>{row.makerCompany}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default AlbumItem;
