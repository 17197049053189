import React, { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";

import HStack from "@components/hstack";
import useIsPhone from "@src/hook/useIsPhone";
import { SongOwner } from "@lib/interfaces";
import { Owner } from "@src/enum/ownerType";


const TitleContain = styled("div")({
  display: "flex",
  alignItems: "center",
  [`span`]: {
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: "8px",
  },
});

const Container = styled(Paper)(({ theme }) => ({
  border: `solid ${theme.palette.primary.main} 1px`,
  borderRadius: "10px",
  overflow: "hidden",
  ["div.item"]: {
    position: "relative",
  },
  ["div.item:not(:last-child):after"]: {
    content: '""',
    position: "absolute",
    width: "calc(100% - 16px)",
    left: "8px",
    height: "2px",
    background: theme.palette.primary.main,
    bottom: "2px",
  },
  [theme.breakpoints.up("sm")]: {
    border: "none",
    borderRadius: "0px",
    ["div.item:not(:last-child):after"]: {
      height: "0px",
    },
    ["div.record"]: {
      flexWrap: "wrap",
    },
  },
}));

const ItemHeader = styled(HStack)(({ theme }) => ({
  padding: theme.spacing(1),
  color: "#5393FF",
  fontWeight: "bold",
  fontSize: "20px",
}));

const ItemContent = styled("div")(({ theme }) => ({
  paddingBottom: "8px",
  [".recordTitle "]: {
    color: "#5393FF",
    fontWeight: "700",
    fontSize: "18px",
    marginBottom: "12px",
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    [`&.record > div`]: {
      backgroundColor: "#FFF",
    },
    [`&:nth-of-type(2n+1)`]: {
      backgroundColor: "#F9F9F9",
    },
    [`&.record > div:nth-of-type(4n+1), &.record > div:nth-of-type(4n+2)`]: {
      backgroundColor: "#F9F9F9",
    },
  },
}));

const DataLabel = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  color: theme.palette.primary.main,
}));

const DataTitleLabel = styled(DataLabel)(() => ({
  fontSize: "16px",
}));

const DataItem = styled(`div`)({
  display: "flex",
  width: "50%",
});

const DataTitle = styled(`div`)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  color: theme.palette.primary.main,
  width: "120px",
  textAlign: "left",
  whiteSpace: "nowrap",
  fontSize: "16px",
  fontWeight: "bold",
}));

const DataContent = styled(`div`)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  fontWeight: "bold",
  fontSize: "16px",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  [`a`]: {
    textDecoration: "none",
    color: "#000",
    cursor: "default"
  },
}));

interface OwnerItemProps extends PropsWithChildren<any> {
  item: SongOwner[];
  type: string;
}

function OwnerItem(props: OwnerItemProps) {
  const { type, item } = props;
  const isPhone = useIsPhone();

  return (
    <>
      <TitleContain>
        {props.children}
      </TitleContain>

      <Container variant="outlined">
        {isPhone && (
          <ItemHeader>
            <Typography variant="subtitle1">
              {type === Owner.Compose && "作曲人"}
              {type === Owner.Lyric && "作詞人"}
            </Typography>
          </ItemHeader>
        )}
        {item.map((e, i) => (
          <ItemContent
            key={`${type}-${i}`}
            className={`item ${e.type?.toLocaleLowerCase()}`}
          >
            {isPhone && (
              <>
                <DataTitleLabel
                  variant="subtitle2"
                  className={`${type === Owner.Record && "recordTitle"}`}
                >
                  {type === Owner.Record ? e.albumName : e.name}
                </DataTitleLabel>
                <DataLabel variant="subtitle2">
                  管理權限來源．{e.owner}
                </DataLabel>
                <DataLabel variant="subtitle2">集管團體．{e.source}</DataLabel>
              </>
            )}

            {!isPhone && (
              <>
                <DataItem>
                  <DataTitle>年度</DataTitle>
                  <DataContent>{e.year}</DataContent>
                </DataItem>
                {type === Owner.Record && (
                  <DataItem>
                    <DataTitle>專輯名稱</DataTitle>
                    <DataContent>
                        <a href="#" title={e.albumName}>
                          {e.albumName}
                        </a>
                      </DataContent>
                  </DataItem>
                )}
                {type === Owner.Record && (
                  <DataItem>
                    <DataTitle>演唱人</DataTitle>
                    <DataContent>{e.name}</DataContent>
                  </DataItem>
                )}
                <DataItem>
                  <DataTitle>著作財產權人</DataTitle>
                  <DataContent>
                    <a href="#" title={e.owner}>
                      {e.owner}
                    </a>
                  </DataContent>
                </DataItem>
                <DataItem>
                  <DataTitle>集管團體</DataTitle>
                  <DataContent>{e.source}</DataContent>
                </DataItem>
                <DataItem>
                  <DataTitle>類型</DataTitle>
                  <DataContent>
                    {type === Owner.Compose && "曲"}
                    {type === Owner.Lyric && "詞"}
                    {type === Owner.Record && "錄音"}
                  </DataContent>
                </DataItem>
              </>
            )}
          </ItemContent>
        ))}
      </Container>
    </>
  );
}

export default OwnerItem;
